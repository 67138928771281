@import "../../styles/Variables.scss";

.zoom-container {
  margin-bottom: -90px;

  #zoom-embeddable-phone-iframe {
    width: 350px;
    height: 630px;
  }

  .list-item {
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .remove-btn {
    margin-left: 75%;
  }

  hr {
    margin: 3px;
  }

  .MuiListItemButton-root.Mui-selected {
    background-color: rgba(237, 99, 58, 0.58);

    &:hover {
      background-color: rgba(237, 99, 58, 0.88);
    }
  }

  .sticky-form {
    position: sticky;
    top: 100px;
  }
}

.assign-dialog {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 700px;
  }
}

.extension-dialog {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 1000px;
  }
}

.MuiTableCell-root {
  height: 80px;
  &.limit-rows {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
}