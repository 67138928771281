@import "../../styles/Variables.scss";
.attorney-picture, .attorney-picture-holder {
  max-width: 250px;
}
.info-holder {
  background-color: $gray;

  .side-details {
    border-left: 1px solid $gray-dark;
  }
  .MuiFormControl-root {
    width: 100%;
    margin: 0;
  }

  @media(max-width: $sm) {
    flex-direction: column-reverse;
    background-color: transparent;
  }
}
