@import "../../../styles/Variables.scss";

.table-filters {
  button {
    margin-right: 10px;
  }
}

.new-lead-holder {
  .MuiPaper-root {
    padding: 30px;
  }
}

.dropzone {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed $gray-dark;
  background-color: $gray;
  cursor: pointer;
}

fieldset {
  .MuiInputBase-multiline {
    min-height: 85px;
  }
}

.dollar-adornment{
  display: flex !important;
}

.table-row-accent {
  background-color: $secondary;
}

.table-cell{
  max-width: 270px;
}

.table-btn-higher {
  max-width: 270px;
  height: 60px !important;
  text-wrap: pretty !important;
  font-size: 14px !important;
}

.lead-details-holder{
  margin-top: 240px;
  .details-holder{
    @media(max-width: $sm) {
      padding: 0 !important;
    }
  }
}

.lead-comment-holder {
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  background-color: $secondary;

  .send-by {
    margin-bottom: 5px;
    font-size: 14px;
    font-style: italic;
  }
}

.sticky-details-top-menu {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  padding: 20px 40px 0;
  height: fit-content;
  min-height: 125px;
  background-color: $gray;
  z-index: 9;
  &.attorney-actions{
    padding: 20px 10px 20px 150px;
  }

  &.mobile {
    min-height: 80px;
  }

  &.move-down{
    margin-top: 100px;
  }
}
.sticky-details-bottom-menu {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: $gray;
  z-index: 1000;
  opacity: 1;
}

.sticky-details-side-menu {
  overflow-y: scroll;
  max-height: 70vh;
  position: fixed;
  top: 180px;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  padding: 20px 30px;
  background-color: $gray;
  z-index: 9;

  &.side-menu-low{
    top: 250px;
  }
}
