@import "../../styles/Variables.scss";

html {
  font-size: 14px;
}
@media (min-width: $sm) {
  html {
    font-size: 16px;
  }
}

.app-logo-img {
  width: 30px;
}
.nav-toolbar {
  align-items: center;
}

.nav-list-item {
  max-width: max-content;
  .nav-btn {
    &:hover,
    &.Mui-selected {
      color: $primary;
      background-color: transparent !important;
    }
  }
  .MuiButtonBase-root {
    min-height: 64px;
  }
}

.notifications-icon {
  cursor: pointer;
  color: $primary !important;
  .new-messages-count {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;

    color: $white-pure;
    background-color: $red;
    border-radius: 20px;

    font-weight: 700;
    font-size: 14px;
  }
}

.notifications-holder {
  .notification {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid $secondary-text;
    }
    &.new {
      background-color: $secondary;
    }
    &:hover {
      background-color: $gray;
    }
  }
}
