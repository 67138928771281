@import "../../styles/Variables.scss";

.long-status{
    height: 60px !important;
    width: 150px;
    white-space: normal !important;
}

.action-row {
    cursor: pointer;
    &:hover{
        background-color: $gray;
    }
}

.sticky-holder{
    height: 70vh;
    overflow: auto;
}