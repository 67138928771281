$primary: #ed633a;

$secondary: #FBEADE;
$secondary-text: #df8e57;

$gray: #f7f7f7;
$gray-dark: #C9C9C9;
$gray-darkest: #393939;

$red: #d32f2f;

$white-pure: #FFFFFF;
$accent-text: #FBE7D9;

$xs: 576px;
$sm: 768px;