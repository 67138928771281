@import "../../../styles/Variables.scss";
.attorneys-card {
  .MuiCardContent-root {
    padding: 0;

    .card-image {
      display: flex;
      align-items: flex-end;
    }

    .card-info {
      background-color: $gray;
      padding: 30px;
    }
  }
}

.attorney-picture-holder {
  margin: 20px auto;
  .attorney-picture {
    border: 5px solid $primary;
  }
}

input[type="file"] {
  display: none;
}
.btn-holder {
  justify-content: flex-end;
}

.registration-holder {
  .steps-holder {
    width: 95%;
    padding: 40px;
    @media (max-width: $xs) {
      padding: 10px;
    }
  }

  .register-step-holder {
    margin: 0 50px;
    background-color: $gray;

    .register-form-holder {
      .MuiTextField-root,
      .MuiFormControl-root {
        margin: 0;
        width: 100%;
      }

      .MuiFormHelperText-root {
        color: $red;
      }

      @media (max-width: $xs) {
        padding: 0 !important;
      }
    }

    .btn-holder {
      margin: 0;
      .btn {
        width: 100px;
      }
    }

    @media (max-width: $xs) {
      background-color: transparent;
      margin: 0;
    }
  }
}

.calendar-holder {
  .fc-daygrid-day-events {
    height: 100px;
    a {
      &.fc-event {
        height: 100px;
      }
    }
  }
}
