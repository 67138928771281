@import "Variables.scss";
@import  "tabulator-tables";

.table-head {
  background-color: $secondary !important;
}
.table-body {
  background-color: $white-pure;
  .year-cell{
      width: 120px;
  }
}

.flex-break{
  flex-basis: 100%;
}

.MuiTablePagination-root {
  .MuiToolbar-root {
    padding-top: 20px;

    .MuiTablePagination-actions {
      color: $primary;
      .MuiButtonBase-root {
        background-color: $secondary;
        border-radius: 10px;
        &:first-child {
          margin-right: 5px;
        }
        &.Mui-disabled {
          background-color: $gray;
        }
      }
    }

    .MuiTablePagination-displayedRows,
    .MuiTablePagination-selectLabel {
      margin: 0;
    }
  }
}

.MuiTable-root {
  .MuiTableCell-root {
    .MuiCheckbox-root {
      .MuiSvgIcon-root {
        color: $primary;
      }
    }
  }
}

.MuiTabs-root {
  .MuiTabs-scroller {
    .MuiButtonBase-root {
      background-color: $secondary;
      color: $secondary-text;
      font-weight: 600;

      &.Mui-selected {
        background-color: $primary;
        color: $white-pure;
      }
    }
  }
}
.MuiDialog-root {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 60px;

      .MuiDialogActions-root {
        margin-top: 20px;
      }
    }
  }
  &.MuiModal-root {
    z-index: 1099;
  }
}

.radio-control {
  .MuiFormLabel-root.Mui-focused{
    color: rgba(0, 0, 0, 0.6);
  }
}

//colors global
.text-accent {
  color: $accent-text;
}

.text-white {
  color: $white-pure;
}

.text-main {
  color: $primary;
}

//general global
.root-container {
  margin-top: 90px;
  margin-bottom: 90px;
}

.title {
  font-weight: 700;
  margin-bottom: 40px;
}

.text-right {
  text-align: right !important;
}

img {
  width: 100%;
  object-fit: cover;
  object-position: 100% 0;
}

.full-btn {
  width: 100%;
  border-radius: 0 !important;
}

.error-message {
  color: $red !important;
}

.width-content {
  width: fit-content !important;
}

.min-width-200 {
  min-width: 200px;
}
.max-width-50{
  max-width: 50px;
}

.multiline {
  .MuiInputBase-root {
    min-height: 150px;
  }
}

.hover-pointer {
  cursor: pointer;
}

.caption-text {
  font-size: 20px;
}

//swall
.swal2-container {
  z-index: 9999 !important;
}

.swal2-actions {
  .swal2-confirm {
    background-color: $primary;
  }
  .swal2-cancel {
    background-color: $gray-dark;
  }
  .swal2-styled {
    width: 140px;
    border-radius: 10px;
  }
}

.MuiDrawer-root.MuiDrawer-modal {
  &.attorney-details-holder {
    z-index: 1100 !important;
  }

  &.lead-details-holder {
    z-index: 1101 !important;
  }
  &.send-details-holder {
    z-index: 1102 !important;
  }
}
.main-holder {
  margin: 0;

  .MuiFormControl-root {
    width: 100%;
    margin: 0;

    &.error {
      fieldset {
        border-color: $red;
      }
    }
  }

  .submit-btn {
    max-width: 200px;
  }

  .side-details {
    //background-color: $gray;

    .MuiTimelineSeparator-root::before {
      display: none;
    }
  }

  .button-holder {
    display: flex;
    justify-content: space-between;

    button {
      width: 180px;
    }
  }

  .col-12,
  .col-6 {
    position: relative;
  }

  .MuiSvgIcon-root[data-testid="VisibilityIcon"],
  .MuiSvgIcon-root[data-testid="VisibilityOffIcon"] {
    cursor: pointer;
  }

  .dropdown-holder,
  .datepicker-holder {
    .MuiSvgIcon-root[data-testid="VisibilityIcon"],
    .MuiSvgIcon-root[data-testid="VisibilityOffIcon"] {
      right: 50px;
    }
  }

  .hide-actions {
    .MuiSvgIcon-root[data-testid="VisibilityIcon"],
    .MuiSvgIcon-root[data-testid="VisibilityOffIcon"],
    .MuiInputAdornment-root,
    .MuiSelect-icon {
      display: none;
    }
  }

  legend{
    font-size: var(--bs-body-font-size);
  }

  .lead-additional-info {
    padding: 60px 25px;
  }

  .attorneys-table {
    background-color: $secondary;
  }
  .inner-table {
    .inner-row {
      padding: 0;
      margin: 0;
    }
  }

  //share
  .border-right {
    border-right: 1px solid $primary;
  }

  .delete-icon {
      cursor: pointer;
      color: $red !important;
  }
}
